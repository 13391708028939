import { Route, BrowserRouter } from 'react-router-dom';
import React from 'react';
import App from './App';
import theme from './Theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import LoggedInUserContext from './loggedInUserContext';
import LoginView from './components/LoginView';
import MainView from './components/MainVIew';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <LoggedInUserContext.Consumer>
        {context => ( 
            <Route {...rest} render={props =>
                context.user_id ? (
                    <Component {...props} />
                ) : (<LoginView {...props} />) }
        />)}
    </LoggedInUserContext.Consumer>
);

const LegalupRouter = () => {
    return <BrowserRouter basename='/'>
        <MuiThemeProvider theme={theme}>
            <App>
                <Route exact path="/login" component={LoginView} />
                <PrivateRoute path="/" component={MainView} />
            </App>
        </MuiThemeProvider>
    </BrowserRouter>;
}

export default LegalupRouter;