import api from '../api';
import { BASE_URL } from '../api';
import { io } from 'socket.io-client';
export const FETCH_USERS = 'FETCH_USERS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS';
export const GET_DOCUMENTS_FROM_SEARCH = 'GET_DOCUMENTS_FROM_SEARCH';
export const GET_DOCUMENTS_TEMPLATES = 'GET_DOCUMENTS_TEMPLATES';
export const COPY_DOCUMENT = 'COPY_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const ASSIGN_DOCUMENT_TO_ORGANIZATION = 'ASSIGN_DOCUMENT_TO_ORGANIZATION';
export const CLEAR_DOCUMENTS_FROM_SEARCH = 'CLEAR_DOCUMENTS_FROM_SEARCH';
export const UPDATE_DOCUMENT_ORGANIZATION = 'UPDATE_DOCUMENT_ORGANIZATION';
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const LOAD_PROGRESS = 'LOAD_PROGRESS';



export const initGlobalWS = (dispatch) => {
    const socket = io(BASE_URL, {
        reconnectionDelayMax: 10000,
        secure: true
    });

    socket.on('connect', () => {
        console.log('socket.io connected - ' + socket.id);

        socket.on('document_cloned', ({request_id, ...document}) => {
            dispatch({ type: COPY_DOCUMENT, push: true, success: true, document});
        });
    });
}


export function fetchUsers() {
    return async (dispatch, getState) => {        
        dispatch({ type: FETCH_USERS });
        try {
            const doc = await api.users.getAll();
            dispatch({ type: FETCH_USERS, success: true, users: doc.users });
        } catch (error) {
            console.error(error);
            dispatch({ type: FETCH_USERS, success: false, error });
        }
    };
}

export function createUser(user) {
    return async (dispatch, getState) => {        
        dispatch({ type: CREATE_USER });
        try {
            const doc = await api.users.create(user.username, '123456');
            console.log(doc);
            dispatch({ type: CREATE_USER, success: true, user: doc });
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_USER, success: false, error });
        }
    };
}

export function updateUser(user) {
    return async (dispatch, getState) => {        
        dispatch({ type: UPDATE_USER });
        try {
            console.log("updating wuith", user);
            const doc = await api.users.update(user);
            dispatch({ type: UPDATE_USER, success: true, user: doc });
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_USER, success: false, error });
        }
    };
}

export function fetchOrganizations() {
    return async (dispatch, getState) => {        
        dispatch({ type: FETCH_ORGANIZATIONS });
        try {
            const doc = await api.users.getOrganizations();
            dispatch({ type: FETCH_ORGANIZATIONS, success: true, organizations: doc.organizations });
        } catch (error) {
            console.error(error);
            dispatch({ type: FETCH_ORGANIZATIONS, success: false, error });
        }
    };
}

export function getDocumentsFromSearch(text) {
    return async (dispatch, getState) => {
        dispatch({ type: GET_DOCUMENTS_FROM_SEARCH });
        try {
            const doc = await api.documents.getDocumentsFromSearch(text);
            dispatch({ type: GET_DOCUMENTS_FROM_SEARCH, success: true, doc });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_DOCUMENTS_FROM_SEARCH, success: false, error });
        }
    };
}

export function getDocumentsTemplates() {
    return async (dispatch, getState) => {
        dispatch({ type: GET_DOCUMENTS_TEMPLATES });
        try {
            const doc = await api.documents.getDocumentsTemplates();
            dispatch({ type: GET_DOCUMENTS_TEMPLATES, success: true, doc });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_DOCUMENTS_TEMPLATES, success: false, error });
        }
    };
}


export function copyDocument(docId) {
    return async(dispatch, state) => {
        dispatch({ type: COPY_DOCUMENT });
        try {
            const doc = await api.documents.copyDocument(docId);
            dispatch({ type: COPY_DOCUMENT, success: true, copiedDocument: doc });
        } catch (error) {
            console.error(error);
            dispatch({ type: COPY_DOCUMENT, success: false, error });
        }
    };
}

export function updateDocumentByTemplates( documentId ) {
    return async (dispatch, getState) => {
        dispatch({ type: UPDATE_DOCUMENT, data: {  is_template: true }});
        try {
            await api.documents.updateDocument(documentId, {  is_template: true });
            dispatch({ type: UPDATE_DOCUMENT, success: true});
        } catch (error ) {
            dispatch({ type: UPDATE_DOCUMENT, success: false, error });
        }
    }
}


export function clearDocumentsFromSearch() {
    return async (dispatch, getState) => {
        dispatch({ type: CLEAR_DOCUMENTS_FROM_SEARCH, success: true, doc: [] });
    };
}

export function updateDocumentOrganization(docId, organizationId) {
    return async (dispatch, getState) => {
        dispatch({ type: UPDATE_DOCUMENT_ORGANIZATION });
        try {
            await api.documents.updateDocumentOrganization(docId, organizationId);
            dispatch({ type: UPDATE_DOCUMENT_ORGANIZATION, success: true});
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_DOCUMENT_ORGANIZATION, success: false, error });
        }
    };
}

export function createOrganization(org) {
    return async (dispatch, getState) => {        
        dispatch({ type: ADD_ORGANIZATION });
        try {
            const doc = await api.users.createOrganization(org);
            dispatch({ type: ADD_ORGANIZATION, success: true, organization: doc });
        } catch (error) {
            console.error(error);
            dispatch({ type: ADD_ORGANIZATION, success: false, error });
        }
    };
}

export function updateOrganization(org) {
    return async (dispatch, getState) => {        
        dispatch({ type: UPDATE_ORGANIZATION });
        try {
            console.log("updating wuith", org);
            const doc = await api.users.updateOrganization(org);
            dispatch({ type: UPDATE_ORGANIZATION, success: true, organization: doc });
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_ORGANIZATION, success: false, error });
        }
    };
}
