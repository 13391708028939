import React, {useState} from 'react';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector, useDispatch} from 'react-redux';
import EditIcon from  '@material-ui/icons/Edit';
import * as Actions from '../redux/actions';

//dialog box
import {TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab}  from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';


const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
}));


function NewUserDialog(props) {
    const {open, handleClose, onAddUser, onUserDocChanged, doc, update} = props;
    const addOrUpdate = update ? 'Update' : 'Add';
    const title = `${addOrUpdate} user`;
    const text = update ? 'Please edit the following details' : 'Please Fill in details for the new user';
    return <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {text}
            </DialogContentText>
            <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                label="User Name"
                name="username"
                autoFocus
                value={doc && doc.username}
                onChange={onUserDocChanged}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                label="Organization Id"
                name="organization_id"
                value={doc && doc.organization_id}
                onChange={onUserDocChanged}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                label="Role"
                name="role"
                value={doc && doc.role}
                onChange={onUserDocChanged}
            />            
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={onAddUser} color="primary">
              {addOrUpdate}
            </Button>
        </DialogActions>
    </Dialog>
}

export default function UsersList() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const users = useSelector(state => state.main.users);
    const organizations = useSelector(state => state.main.organizations);

    const [open, setOpen] = React.useState(false);
    const [clickedRow, setClickedRow] = useState(undefined);
    const [newUserDoc, setNewUserDoc] = useState(undefined);


    //dialog box state and functions
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = async () => {
        await setClickedRow(undefined)
        await setNewUserDoc(undefined);
        await setOpen(false);
    };

    const onUsersRowsDelete = async () => {
        return false;
    };

    const addUser = async () => {
        if (newUserDoc && newUserDoc.username)  {
            if (clickedRow) { 
                await dispatch(Actions.updateUser(newUserDoc));
            } else {
                await dispatch(Actions.createUser(newUserDoc));
            }
        }
        handleClose();
    };
    
    const onEditClick = async (idx) => {
        await setClickedRow(users[idx]);
        await setNewUserDoc(users[idx]);        
        await setOpen(true);        
  };

  const onUserDocChanged = async e => {
      await setNewUserDoc(Object.assign({}, newUserDoc, {[e.target.name]: e.target.value}));
  };

    const columns = [{
        name: "username",
        label: "Username",
        options: {
            filter: true,
            sort: true,
        }
    }, {
        name: "organization_id",
        label: "Organization",
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? organizations.find(org => org.id === value).name : undefined; 
            },
        }
    }, {
        name: "role",
        label: "Role",
        options: {
            filter: true,
            sort: true,
        }
    }, {
        name: "updated_at",
        label: "Updated At",
        options: {
            filter: true,
            sort: true,
        }
    },{
      name: "actions",
      label: "Actions",
      options: {
          filter: false,
          sort: false,        
          customBodyRender: (value, tableMeta, updateValue) => {
              return (
                  <EditIcon onClick={(e) => {
                      onEditClick(tableMeta.rowIndex);
                      e.stopPropagation();
                      return true;                
                  }} />
              );
          },
      }
  }];

    return(
        <React.Fragment>
            <MUIDataTable 
                title={"Users"} 
                data={users} 
                columns={columns} 
                options={{
                    filterType: 'dropbdown',
                    search: true,
                    pagination: true,
                    sort: true,
                    viewColumns: false,
                    onRowsDelete: onUsersRowsDelete
                }} 
            />
            <Fab color="primary" aria-label="add" onClick={handleClickOpen} className={classes.margin}>
                <AddIcon />
            </Fab>
            <NewUserDialog
                open={open}
                handleClose={handleClose}
                onUserDocChanged={onUserDocChanged}
                onAddUser={addUser}
                doc={newUserDoc}
                update={clickedRow!==undefined}
            />
        </React.Fragment>
    );
}
