import React, {useState} from 'react';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector, useDispatch} from 'react-redux';
//dialog box
import {TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab}  from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AssignDocumentForOrganizationDialog from './AssignDocumentForOrganizationDialog';
import DescriptionIcon from '@material-ui/icons/Description';
import AddIcon from '@material-ui/icons/Add';
import * as Actions from '../redux/actions';



const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
    cursor: {
      cursor: "pointer",
        marginRight: '5px',
    }
}));

function NewOrganizationDialog(props) {
    const {open, handleClose, onAddOrganization, onChange, doc, update} = props;
    const addOrUpdate = update ? 'Update' : 'Add';
    const title = `${addOrUpdate} Organization`;
    const text = update ? 'Please edit the following details' : 'Please Fill in details for the new organization';
    return <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
              {text}
            </DialogContentText>
            <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                label="Name"
                name="name"
                autoFocus
                value={doc && doc.name}
                onChange={onChange} />
        </DialogContent>
        <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={onAddOrganization} color="primary">
                {addOrUpdate}
              </Button>
        </DialogActions>
    </Dialog>
}


export default function OrganizationsList(props) {
    const classes = useStyles();

    //dialog
    const [open, setOpen] = useState(false);
    const [openAssignDialog, setOpenAssignDialog] = useState(false);
    const [newOrgDoc, setNewOrgDoc] = useState(undefined);
    const [clickedRow, setClickedRow] = useState(undefined);
    const organizations = useSelector(state => state.main.organizations);
    const dispatch = useDispatch();
    
    const onOrganizationRowsDelete = (rowsDeleted, data) => {
        console.log(rowsDeleted, data);
        return false;
    };
    const handleClickOpen = async () => {
      await setOpen(true);
    };

    const handleClose = async () => {
      await setOpen(false);
      await setClickedRow(undefined);
      await setNewOrgDoc(undefined);
    };

    const handleCloseAssignDocumentDialog = async () => {
        await setOpenAssignDialog(false);
        await setNewOrgDoc(undefined);
    };
  
    const onOrganizationDocChange = async e => {
        await setNewOrgDoc(Object.assign({}, newOrgDoc, {[e.target.name]: e.target.value}));
    };
    
    const AddOrganization = async () => {
        if (newOrgDoc && newOrgDoc.name)  {
            if (clickedRow) { 
                await dispatch(Actions.updateOrganization(newOrgDoc));
            } else {
                await dispatch(Actions.createOrganization(newOrgDoc));
            }
        }
        handleClose();
    }    

    const onEditClick = async (idx) => {
        await setClickedRow(organizations[idx]);
        await setNewOrgDoc(organizations[idx]);        
        await setOpen(true);        
    };

    const onAssignClick = async (idx) => {
        await setNewOrgDoc(organizations[idx]);
        await setOpenAssignDialog(true);
    };

    const columns = [{
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },{
            name: "id",
            label: "Id",
            options: {
                filter: true,
                sort: true,
            },
        }, {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,        
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (

                        <>
                        <EditIcon className={classes.cursor} onClick={(e) => {
                            onEditClick(tableMeta.rowIndex);
                            e.stopPropagation();
                            return true;                
                        }} />

                        <DescriptionIcon className={classes.cursor} onClick={(e) => {
                            onAssignClick(tableMeta.rowIndex);
                            e.stopPropagation();
                            return true;
                        }} />

                        </>
                    );
                },
            }
        }
    ];

    return(
        <React.Fragment>
            <MUIDataTable 
                title={"Organizations"} 
                data={organizations} 
                columns={columns} 
                options={{
                    filterType: 'dropbdown',
                    search: true,
                    pagination: true,
                    sort: true,
                    viewColumns: false,
                    onRowsDelete: onOrganizationRowsDelete
                }} 
            />
            <Fab color="primary" aria-label="add" onClick={handleClickOpen} className={classes.margin}>
                <AddIcon />
            </Fab>
            <NewOrganizationDialog 
                open={open} 
                onChange={onOrganizationDocChange} 
                handleClose={handleClose} 
                onAddOrganization={AddOrganization}
                doc={newOrgDoc}
                update={clickedRow!==undefined}
            />

            {newOrgDoc &&
                <AssignDocumentForOrganizationDialog
                    open={openAssignDialog}
                    handleClose={handleCloseAssignDocumentDialog}
                    organization={newOrgDoc}
                />
            }


        </React.Fragment>
      );
}
