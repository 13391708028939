import React, {useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector, useDispatch} from 'react-redux';
//dialog box
import {
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    LinearProgress
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import UpdateDocumentForTemplatesDialog from './UpdateDocumentForTemplatesDialog';
import OrganizationsListInTemplates from './OrganizationsListInTemplates';
import DescriptionIcon from '@material-ui/icons/Description';
import AddIcon from '@material-ui/icons/Add';
import * as Actions from '../redux/actions';



const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
    cursor: {
      cursor: "pointer",
        marginRight: '5px',
    },
    progress: {
        width: '100%',
        marginBottom: '18px',
        marginTop: '10px',
    },
}));



export default function TemplatesList(props) {
    const classes = useStyles();

    //dialog
    const [open, setOpen] = useState(false);
    const [openAssignDialog, setOpenAssignDialog] = useState(false);
    const [newDocOrg, setNewDocOrg] = useState(undefined);
    const dispatch = useDispatch();
    const documents = useSelector(state => state.main.docTemplates);
    const loadingPhase = useSelector(state => state.main.loadingPhase);

    useEffect(() => {
        dispatch(Actions.getDocumentsFromSearch());
        dispatch(Actions.getDocumentsTemplates());
    }, [])

    const onOrganizationRowsDelete = (rowsDeleted, data) => {
        console.log(rowsDeleted, data);
        return false;
    };
    const handleClickOpen = async () => {
      await setOpen(true);
    };

    const handleCloseUpdateDocumentForTemplatesDialog = async () => {
        await setOpen(false);
        dispatch(Actions.getDocumentsTemplates());
    };

    const handleCloseOrganizationsListInTemplates = async () => {
        await setOpenAssignDialog(false);
    };


    const onAssignClick = async (idx) => {
        await setNewDocOrg(documents[idx]);
        await setOpenAssignDialog(true);
    };

    const columns = [{
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            },
        },{
            name: "organization_id",
            label: "Organization id",
            options: {
                filter: true,
                sort: true,
            },
        }, {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,        
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                        <DescriptionIcon className={classes.cursor} onClick={(e) => {
                            onAssignClick(tableMeta.rowIndex);
                            e.stopPropagation();
                            return true;
                        }} />
                        </>
                    );
                },
            }
        }
    ];

    return(
        <React.Fragment>
            {loadingPhase==='loading' && <LinearProgress className={classes.progress} color="primary" />}
            <MUIDataTable 
                title={"Templates"}
                data={documents}
                columns={columns} 
                options={{
                    filterType: 'dropbdown',
                    search: true,
                    pagination: true,
                    sort: true,
                    viewColumns: false,
                    onRowsDelete: onOrganizationRowsDelete
                }} 
            />
            <Fab color="primary" aria-label="add" onClick={handleClickOpen} className={classes.margin}>
                <AddIcon />
            </Fab>

            {open &&
                <UpdateDocumentForTemplatesDialog
                    open={open}
                    handleClose={handleCloseUpdateDocumentForTemplatesDialog}
                />
            }

            { openAssignDialog &&
                <OrganizationsListInTemplates
                    open={openAssignDialog}
                    handleClose={handleCloseOrganizationsListInTemplates}
                    doc={newDocOrg}
                />
            }


        </React.Fragment>
      );
}
