import React, {useState, useEffect} from 'react';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector, useDispatch} from 'react-redux';
//dialog box
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputBase,
    fade,
    LinearProgress
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import * as Actions from '../redux/actions';

const useStyles = makeStyles(theme => ({
    search: {
        position: 'relative',
        border: '1px solid rgba(33, 33, 33, 0.1)',
        borderRadius: '16px',
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.02),
        },
        marginRight: 0,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        width: '56px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputSearch: {
        color: 'inherit',
        paddingLeft: '56px',
    },
    flexGrow: {
        flexGrow: 1,
        overflowY: 'auto',
        marginTop: '30px',
    },
    noScroll: {
        overflowY: 'hidden',
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    cursor: {
        cursor: "pointer",
        marginRight: '5px',
    },
    color: {
        color: '#9c27b0',
        marginTop: '30px',
    },
    pointerEvents: {
        pointerEvents: 'none',
    },
    progress: {
        width: '100%',
        marginBottom: '18px',
        marginTop: '10px',
    },
}));

export default function AssignDocumentForOrganizationDialog(props) {
    const {open, organization, handleClose} = props;
    const organizationId = organization.id;
    const [searched, setSearched] = useState("");
    const [docId, setDocId] = useState(undefined);

    const documents = useSelector(state => state.main.doc);
    const updateDocumentAssign = useSelector(state => state.main.updateDocumentAssign);
    const loadingPhase = useSelector(state => state.main.loadingPhase);
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if(searched.length > 0){
            const delayDebounceFn = setTimeout(() => {
                dispatch(Actions.getDocumentsFromSearch(searched));
            }, 500)
            setDocId(undefined);
            return () => clearTimeout(delayDebounceFn);
        }
    }, [searched])

    useEffect(() => {
        if(docId && docId.id && organizationId){
            dispatch(Actions.updateDocumentOrganization(docId.id, organizationId))
        }
    }, [docId])

    useEffect(() => {
        if(updateDocumentAssign){
            const delayDebounceFn = setTimeout(() => {
                handleCloseAssignDialog();
            }, 2000)
            return () => clearTimeout(delayDebounceFn);
        }
    }, [updateDocumentAssign])

    const title = `Assign document for "${organization.name}"`;
    const text =  `Find a document and select it to assign it to this organization`;

    const requestSearch = (event) => {
        setSearched(event.target.value);
    };

    const onAssignClick = async (idx) => {
        await setDocId(documents[idx]);
    };

    const handleCloseAssignDialog = () => {
        dispatch(Actions.clearDocumentsFromSearch());
        handleClose();
    };

    const columns = [{
        name: "name",
        label: "Name",
    }, {
        name: "id",
        label: "Id",

    }, {
        name: "Change",
        label: "Change organization",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                        <div className={classes.actionsContainer}>
                            <AccessibilityNewIcon
                                className={`${classes.cursor} ${loadingPhase==='loading' && classes.pointerEvents }`}
                                onClick={(e) => {
                                    onAssignClick(tableMeta.rowIndex);
                                    e.stopPropagation();
                                    return true;
                                }}
                            />
                        </div>
                )
            }
        }
    }];

    const options = {
        filter: false,
        page: 0,
        rowsPerPage: 20,
        rowsPerPageOptions: [20],
        download: false,
        print: false,
        select: false,
        search: false,
        pagination: false,
        customToolbar: null,
        selectableRows: false,
        sort: false,
        serverSide: true,
        viewColumns: false,
        expandableRows: false,
        expandableRowsOnClick: false,
    };

    return (
        <Dialog className='dialogAssignDocuments' open={open} onClose={handleCloseAssignDialog} fullWidth maxWidth={'md'} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>

                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        fullWidth
                        placeholder="Enter document name or full id"
                        className={classes.inputSearch}
                        inputProps={{ 'aria-label': 'search' }}
                        value={searched}
                        onChange={(e) => requestSearch(e)}
                    />
                </div>

                {updateDocumentAssign && <div className={classes.color}>
                    Document successfully changed!
                </div>}

                {loadingPhase==='loading' && <LinearProgress className={classes.progress} color="primary" />}

                { documents.length ?
                    <MUIDataTable
                        className={classes.flexGrow}
                        data={documents}
                        columns={columns}
                        options={options}
                    /> : ''
                }

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAssignDialog} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
}