import * as Actions from '../actions';
import { createReducer } from '@reduxjs/toolkit';
import {ASSIGN_DOCUMENT_TO_ORGANIZATION, GET_DOCUMENTS_TEMPLATES} from "../actions";

const initialState = {
    loadingPhase: '',
    users: [],
    organizations: [],
    doc: [],
    docTemplates: [],
    copiedDocument:null,
    updateDocumentAssign: false
};

const fetchUsers = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.users = action.users;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
};

const createUser = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.loadingPhase = '';
        state.users = [...state.users, action.user];
    } else {
        state.loadingPhase = 'fetching';
    }
};

const updateUser = (state, action) => {
    if (typeof action.success === 'boolean') {
        const idx = state.users.findIndex(u => u.id === action.user.id);
        state.users[idx] = action.user;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
};

const fetchOrganizations = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.organizations = action.organizations;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
};

const getDocumentsFromSearch = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.doc = action.doc.documents;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'loading';
    }
};
const getDocumentsTemplates = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.docTemplates = action.doc.documents;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'loading';
    }
};

const copyDocument = (state, action) => {
    if (action.success) {
        state.copiedDocument = action.document;
    }
    else {
        state.loadingPhase = 'loading';
    }
}

const updateDocument = (state, action) => {
    if (action.data){
        for(const key in action.data) {
            state[key] = action.data[key]
        }
    }

    if (typeof action.success === 'boolean') {
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'loading';
    }
}



const clearDocumentsFromSearch = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.doc = action.doc;
        state.updateDocumentAssign = false;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
};

const updateDocumentOrganization = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.updateDocumentAssign = action.success;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'loading';
    }
};

const createOrganization = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.organizations = [...state.organizations, action.organization];        
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
};

const updateOrganization = (state, action) => {
    if (typeof action.success === 'boolean') {
        console.log(action, state.organizations);
        const idx = state.organizations.findIndex(org => org.id === action.organization.id);
        state.organizations[idx] = action.organization;
        state.loadingPhase = '';
    } else {
        state.loadingPhase = 'fetching';
    }
};

const handlers = {};
handlers[Actions.FETCH_USERS] = fetchUsers;
handlers[Actions.FETCH_ORGANIZATIONS] = fetchOrganizations;
handlers[Actions.GET_DOCUMENTS_FROM_SEARCH] = getDocumentsFromSearch;
handlers[Actions.GET_DOCUMENTS_TEMPLATES] = getDocumentsTemplates;
handlers[Actions.CLEAR_DOCUMENTS_FROM_SEARCH] = clearDocumentsFromSearch;
handlers[Actions.UPDATE_DOCUMENT_ORGANIZATION] = updateDocumentOrganization;
handlers[Actions.ADD_ORGANIZATION] = createOrganization;
handlers[Actions.UPDATE_ORGANIZATION] = updateOrganization;
handlers[Actions.CREATE_USER] = createUser;
handlers[Actions.UPDATE_USER] = updateUser;
handlers[Actions.COPY_DOCUMENT] = copyDocument;
handlers[Actions.UPDATE_DOCUMENT] = updateDocument;

export default createReducer(initialState, handlers);