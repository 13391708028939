import React, {useState, useContext} from 'react';
import {Redirect} from 'react-router-dom';
import Api from '../api';
import LoggedInUserContext from '../loggedInUserContext';

import GavelIcon from '@material-ui/icons/Gavel';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography, LinearProgress, Avatar, CssBaseline, Container} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
    const classes = useStyles();
    // const [email, setEmail] = useState(undefined);
    // const [password, setPassword] = useState(undefined);
    const [fields, setFields] = useState({});    
    const [isLoading, setIsLoading] = useState(false);
    const [redirectHome, setRedirectHome] = useState(false);
    let userContext = useContext(LoggedInUserContext);

    const onChange = e => {
        const newFields = Object.assign({}, fields, {[e.target.name]: e.target.value});
        setFields(newFields);
    };

    // when working with server use this
    const handleClick = async (e) => {
        try {
            await setIsLoading(true);
            const user = await Api.users.authenticate(fields.email, fields.password);
            await userContext.update(user.id);
            await setRedirectHome(true);
        }
        catch(e) {
            console.error(e);
        } finally {
            await setIsLoading(false);
        }
    };

  return redirectHome ? (<Redirect to='/' />) : (
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <GavelIcon />
        </Avatar>
        <Typography variant="h4">
          Login
        </Typography>
          <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={fields.email}
              onChange={onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            name="password"
            label="Password"
            autoComplete="current-password"
            onChange={onChange}
            value = {fields.password}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleClick}
          >
            Log In
          </Button>
      </div>
      {isLoading && <LinearProgress className={classes.progress} />}
    </Container>
  );
}