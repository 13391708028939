import React, {useState, useEffect} from 'react';
import MUIDataTable from "mui-datatables";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector, useDispatch} from 'react-redux';
//dialog box
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    fade, LinearProgress,
} from '@material-ui/core';

import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import * as Actions from '../redux/actions';

const useStyles = makeStyles(theme => ({
    search: {
        position: 'relative',
        border: '1px solid rgba(33, 33, 33, 0.1)',
        borderRadius: '16px',
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.02),
        },
        marginRight: 0,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        width: '56px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputSearch: {
        color: 'inherit',
        paddingLeft: '56px',
    },
    flexGrow: {
        flexGrow: 1,
        overflowY: 'auto',
        marginTop: '30px',
    },
    noScroll: {
        overflowY: 'hidden',
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    cursor: {
        cursor: "pointer",
        marginRight: '5px',
    },
    color: {
        color: '#9c27b0',
        marginTop: '30px',
        marginBottom: '18px',
    },
    pointerEvents: {
        pointerEvents: 'none',
    },
    progress: {
        width: '100%',
        marginBottom: '18px',
        marginTop: '10px',
    },
}));

export default function OrganizationsListInTemplates(props) {
    const {open, handleClose, doc} = props;
    const organizations = useSelector(state => state.main.organizations);
    const [orgId, setOrgId] = useState(undefined);
    const copiedDocument = useSelector(state => state.main.copiedDocument);
    const updateDocumentAssign = useSelector(state => state.main.updateDocumentAssign);
    const loadingPhase = useSelector(state => state.main.loadingPhase);
    const classes = useStyles();
    const dispatch = useDispatch();

    const title = `Assign organization for "${doc.name}"`;

    useEffect(() => {
        const init = async () => {
            try {
                if(orgId && orgId.id ){
                    await dispatch(Actions.copyDocument(doc.id));
                }
            } catch (error) {
            }
        }
        init();
    }, [orgId && orgId.id])

     useEffect(() => {
     const init = async () => {
         try {
             if (orgId && orgId.id) {
                 await dispatch(Actions.updateDocumentOrganization(copiedDocument.id, orgId.id));
             }
         } catch (error) {
         }
     }
        init();
     }, [copiedDocument && copiedDocument.id])

    useEffect(() => {
        if(updateDocumentAssign){
            const delayDebounceFn = setTimeout(() => {
                dispatch(Actions.clearDocumentsFromSearch());
                handleClose();
            }, 2000)
            return () => clearTimeout(delayDebounceFn);
        }
    }, [updateDocumentAssign])

    const onAssignClick = async (idx) => {
        await setOrgId(organizations[idx]);
    };


    const columns = [{
        name: "name",
        label: "Name",
        options: {
            filter: true,
            sort: true,
        },
    },{
        name: "id",
        label: "Id",
        options: {
            filter: true,
            sort: true,
        },
    }, {
        name: "actions",
        label: "Actions",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <>
                        <AccessibilityNewIcon
                            className={`${classes.cursor} ${loadingPhase==='loading' && classes.pointerEvents }`}

                            onClick={(e) => {
                            onAssignClick(tableMeta.rowIndex);
                            e.stopPropagation();
                            return true;
                        }} />
                    </>
                );
            },
        }
    }
    ];

    return (
        <Dialog className='dialogAssignDocuments' open={open} onClose={handleClose} fullWidth maxWidth={'md'} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}
                {updateDocumentAssign && <div className={classes.color}>
                    Successfully changed!
                </div>}
                {loadingPhase==='loading' && <LinearProgress className={classes.progress} color="primary" />}
            </DialogTitle>
            <DialogContent>
                    <MUIDataTable
                        title={"Organizations"}
                        data={organizations}
                        columns={columns}
                        options={{
                            filterType: 'dropbdown',
                            search: true,
                            pagination: true,
                            sort: true,
                            viewColumns: false,
                        }}
                    />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>)
}