import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import UsersList from './UsersList';
import OrganizationsList from './OrganizationsList';
import TemplatesList from './TemplatesList';
import {useDispatch} from 'react-redux';
import * as Actions from '../redux/actions';

// Material-ui
import { makeStyles } from '@material-ui/core/styles';
import {Drawer, AppBar, Toolbar, List, Typography, Divider, IconButton, Badge, Link, Grid, Paper, Box, Container, ListItem, ListItemText, ListItemIcon} from '@material-ui/core';

// Icons
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://legalup.me" target="_blank">
        legalup.me
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}
// make the drawer width bigger or smaller, depends on the max text field length
const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  defaultWindow: {
      textAlign: 'center',
  }
}));



export default function Dashboard(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false);
    const [showUsers, setShowUsers] = useState(true);
    const [showOrganization, setShowOrganization] = useState(false);
    const [showTemplates, setShowTemplates] = useState(false);
    const dispatch = useDispatch();
    // const organizations = useSelector(state => state.main.organizations);
    const loadStuff = async () => {
        await dispatch(Actions.fetchOrganizations());        
        await dispatch(Actions.fetchUsers());
    };

    useEffect(() => {
        loadStuff();
        Actions.initGlobalWS(dispatch)
    }, []);


    const toggleDrawerHandler = () => {
        setOpen(!open);
    }
    const usersClicked = () => {
        setShowOrganization(false);
        setShowUsers(true);
        setShowTemplates(false);
    };
    const organizationClicked = () => {
        setShowOrganization(true);
        setShowUsers(false);
        setShowTemplates(false);
    };

    const templatesClicked = () => {
        setShowTemplates(true);
        setShowOrganization(false);
        setShowUsers(false);
    };


    
    return (
        <div className={classes.root}>
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawerHandler}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon fontSize="large"/>
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                      LegalUp Archive (Super Admin)
                    </Typography>
                    <IconButton color="inherit">
                        <Badge color="secondary">
                            <AccountCircleIcon fontSize="large"/>
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                  paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={toggleDrawerHandler}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <div>
                        <ListItem button onClick={usersClicked}>
                            <ListItemIcon>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Users" />
                        </ListItem>
                        <ListItem button onClick={organizationClicked}>
                            <ListItemIcon>
                                <BusinessIcon />
                            </ListItemIcon>
                            <ListItemText primary="Organizations" />
                        </ListItem>
                        <ListItem button onClick={templatesClicked}>
                            <ListItemIcon>
                                <VerticalSplitIcon />
                            </ListItemIcon>
                            <ListItemText primary="Templates" />
                        </ListItem>
                    </div>
                </List>
                <Divider />
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                {showUsers && <Container className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <UsersList />
                        </Paper>
                        </Grid> 
                    </Grid>
                 </Container>
                }
                {showOrganization && <Container className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <OrganizationsList />
                        </Paper>
                        </Grid>
                    </Grid>
                </Container>
                }

                {showTemplates && <Container className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <TemplatesList />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
                }


                <div className={classes.appBarSpacer} />
                <Box pt={4}>
                    <Copyright />
                </Box>
            </main>
      </div>
    );
}
